import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

type CurrentPlatform = 'browser' | 'native' | 'mobile';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  private _currentPlatform: CurrentPlatform;
  private platformName:any

  constructor(private platform: Platform) {
    this.setCurrentPlatform();
   }


  private setCurrentPlatform() {
    // Are we on mobile platform? Yes if platform is ios or android, but not desktop or mobileweb, no otherwise
    if (
        this.platform.is('ios')
        || this.platform.is('android')
        && !( this.platform.is('desktop') || this.platform.is('mobileweb') ) ) {
      this._currentPlatform = 'mobile';
    } else {
      this._currentPlatform = 'browser';
    }

    if(this.platform.is('ios')) {
      this.platformName = 'ios'
    }
    if(this.platform.is('android')) {
      this.platformName = 'android'
    }
    if(this.platform.is('mobileweb') || this.platform.is('desktop')) {
      this.platformName = 'browser'
    }

    //console.log('Current Platform', {_currentPlatform: this._currentPlatform, platformName: this.platformName})

  }

    get currentPlatform() {
      return this._currentPlatform;
    }

    isNative() {
      return this._currentPlatform === 'mobile';
    }
    isBrowser() {
      return this._currentPlatform === 'browser';
    }
    isAndroid() {
      return this.platformName === 'android';
    }
    isIos() {
      return this.platformName === 'ios';
    }


}
