import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./privacy/privacy.module').then( m => m.PrivacyPageModule)
  },
  {
    path: 'favorite',
    loadChildren: () => import('./favorite/favorite.module').then( m => m.FavoritePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'place-detail/:placeID',
    loadChildren: () => import('./place-detail/place-detail.module').then( m => m.PlaceDetailPageModule),
  },
  {
    path: 'order-detail/:orderId',
    loadChildren: () => import('./order-detail/order-detail.module').then( m => m.OrderDetailPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then( m => m.AccountPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'geolocalisation',
    loadChildren: () => import('./geolocalisation/geolocalisation.module').then( m => m.GeolocalisationPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path:'parameter',
    children: [
  {
    path: '',
    loadChildren: () => import('./parameter/parameter.module').then( m => m.ParameterPageModule)
  } , {
    path: 'device',
    loadChildren: () => import('./device/device.module').then( m => m.DevicePageModule)
  }
  ]},
  {
    path: 'phone',
    loadChildren: () => import('./phone/phone.module').then( m => m.PhonePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'phone/:number',
    loadChildren: () => import('./phone/phone.module').then( m => m.PhonePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'promotion',
    loadChildren: () => import('./promotion/promotion.module').then( m => m.PromotionPageModule),
    canActivate: [AuthGuardService]
  },
  
  
  
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
