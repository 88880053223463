import { Injectable } from '@angular/core';
import firebase from 'firebase';
import { Platform } from '@ionic/angular';
import { Component, NgZone } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  db:any
  order:any = []

  constructor(private platform: Platform, private zone: NgZone) {

      this.platform.ready().then(async () => {
        this.db = firebase.firestore()
      })
  }

  getUser() {
    const user = firebase.auth().currentUser
    return this.db.collection("stripe_customers").doc(user.uid)
  }

  getPASS() {
    const user = firebase.auth().currentUser
    return this.db.collection("cartes").where("userId", "==", user.uid)
  }

  getOpenPASS(uid) {
    const user = firebase.auth().currentUser
    return this.db.collection("cartes").where("userId", "==", uid)
  }

  getPlace(docId:string) {
    return this.db.collection("posts").doc(docId).get()
  }

  getPromotion(id:any) {
    return this.db.collection("promotions").where("number", "==", id).where("state", "==", false).get()
  }

  getNotificationCategories() {
    return this.db.collection("categories_notifications").get()
  }

  getPlaces() {
    return this.db.collection("posts").where("status", "==", "published")
  }

  getPartners() {
    return this.db.collection("partners").where("status", "==", "published")
  }

  getParameters(uid:string) {
    return this.db.collection("user_parameters").doc(uid)
  }

  getUserPlaces(list:any[]) {
    return this.db.collection("posts").where("docID", "in", list).get()
  }

  getuserNotificationsCategory() {
    const user = firebase.auth().currentUser
    return this.db.collection("user_notifications_category").where("uid", "==", user.uid).get()
  }

  createMessage(data:any) {
    return this.db.collection("messages").add(data)
  }

  createSms(data:any) {
    return this.db.collection("messages_sms").add(data)
  }

  createWhatsapp(data:any) {
    return this.db.collection("messages_whatsapp").add(data)
  }


  getOrders(uid:string) {
    return this.db.collection("orders").where("uid", "==", uid)
  }

  checkOrder(orderNumber:any) {
    return this.db.collection("orders").where("orderNumber", "==", orderNumber)
  }

  getDevices(uid:string) {
    return this.db.collection("devices").where("userId", "==", uid)
  }

  updatePASS(uid:string, state:boolean) {
    return this.db.collection("cartes").where("userId", "==", uid).update(
      {
         status: state
      }, {merge:true}
      )
  }

  updateOrder(docId, data) {
    return this.db.collection("orders").doc(docId).update(
      {
        status:"terminated",
        cart: data,
        terminated: Math.floor(Date.now())
      }, {merge:true}
      )
  }


  updateProducts(docId:string, data:any) {
    return this.db.collection("orders").doc(docId).update(
      {
        cart: data
      }, {merge:true}
      )
  }

  //Stats
  getOrdersStats(place:any) {
    return this.db.collection("orders").where("place", "==", place).get()
  }

  saveDeviceParameters(uuid:string, device:any) {
    device['uuid'] = uuid
    return this.db.collection("devices").doc(uuid).set(device)
  }

  saveTokenPushNotification(token) {
    const user = firebase.auth().currentUser
    return this.db.collection('stripe_customers').doc(user.uid).set({
      FCMToken: token,
    }, { merge: true })
  }

  async saveSyncParameters(uid:any, parameter:any) {
    const user = firebase.auth().currentUser.uid
    parameter['uid'] = uid
    this.updateCarte(uid, parameter.enablePASS)
    return this.db.collection("user_parameters").doc(uid).set(parameter)
  }

  async saveSyncEnablePASS(uid:any, enablePASS:boolean) {
    const user = firebase.auth().currentUser.uid
    this.updateCarte(uid, enablePASS)
    return this.db.collection("user_parameters").doc(uid).set({enablePASS:enablePASS}, { merge: true })
  }

  updateGeolocalisationState(uid, state, lat, lng, city, lastUpdate) {
    return this.db.collection("user_geolocalisation").doc(uid).set({ geolocalisation: state, coords: {lat:lat, lng:lng, city:city}, lastUpdate }, { merge: true })
  }

  updateNotificationSubscribe(uid:string, state:boolean, notification:any) {
    notification['uid'] = uid
    notification['state'] = state
    if(state == true) {
    return this.db.collection("user_notifications_category").doc(notification.slug +'-' + uid).set(
      notification
      )
    } else {
      return this.db.collection("user_notifications_category").doc(notification.slug +'-' + uid).set(
        notification
        )
    }
  }

  updateProfile(data) {
    const user = firebase.auth().currentUser
    return this.db.collection('stripe_customers').doc(user.uid).update({
      firstname: data.prenom,
      lastname: data.nom
    })
  }

  updatePromotion(docId) {
    return this.db.collection('promotions').doc(docId).update({
      state: true,
    })
  }

  updatePhoneNumber(data) {
    const user = firebase.auth().currentUser
    return this.db.collection('stripe_customers').doc(user.uid).update({
      phone: data.phone,
    })
  }

  async updateCarte(uid, state) {
    const carteUpdate = await this.db.collection('cartes').where("userId", "==", uid).get()
    carteUpdate.forEach(doc => {
	   return this.db.collection('cartes').doc(doc.id).set({
      status: state,
    }, { merge: true })
	});
  }


  async preparePASS(uid):Promise <boolean> {
    return new Promise(async (resolve, reject) => {
    const carteUpdate = await this.db.collection('cartes').where("userId", "==", uid).get()
    if(carteUpdate.empty) {
     return reject()
    }
      carteUpdate.forEach(async (doc) => {
      const truc = await this.db.collection('cartes').doc(doc.id).set(
          {
          open: true,
          lastOpened: Math.round(new Date().getTime() / 1000)
          }, { merge: true })
          return resolve(true)
          })
    })
  }

  updateBiometric(state) {
    const user = firebase.auth().currentUser
    return this.db.collection('stripe_customers').doc(user.uid).update({
      biometric: state,
    })
  }

  updateAvatar(photoURL) {
    const user = firebase.auth().currentUser
    return this.db.collection('stripe_customers').doc(user.uid).update({
      photoURL: photoURL,
      avatar: photoURL,
    })
  }

  createOrder(data:any) {
    return this.db.collection("orders").add(data)
  }

}
