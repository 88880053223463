import { Injectable } from '@angular/core';
import { FirestoreService } from './firestore.service';

@Injectable({
  providedIn: 'root'
})
export class PartnersService {

  partners:any = []

  constructor(
    private firestore: FirestoreService, 
  ) {
    this.firestore.getPlaces().onSnapshot((querySnapshot) => {
     this.partners = [];
     querySnapshot.forEach((doc) => {
       const data = doc.data()
       data['docId'] = doc.id
       this.partners.push(data);
     });
 });
  }


  
  getAllPlaces() {
    return this.partners
   }

   getPlaceById(docID) {
    const result = this.partners.filter((obj) => {
      return obj.docID === docID;
    });
    return result
   }

   getPlaceImage(docID) {
    const result = this.partners.filter((obj) => {
      return obj.docID === docID;
    });
    return result[0].imageURL
   }


}
