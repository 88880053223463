import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AndroidFullScreen } from '@awesome-cordova-plugins/android-full-screen/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { PartnersService } from './services/partners.service';
//import { SmsRetriever } from '@ionic-native/sms-retriever/ngx'


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,  ServiceWorkerModule.register('ngsw-worker.js', {
  enabled: environment.production,
  // Register the ServiceWorker as soon as the application is stable
  // or after 30 seconds (whichever comes first).
  registrationStrategy: 'registerWhenStable:30000'
})],
  providers: [PartnersService, /*SmsRetriever,*/, SocialSharing,{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, AndroidFullScreen],
  bootstrap: [AppComponent],
})
export class AppModule {}
