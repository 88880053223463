import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController, Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import firebase from 'firebase';
import { Toast } from '@capacitor/toast';
import { Storage } from '@capacitor/storage';
import {cfaSignIn, cfaSignOut, cfaSignInPhone,  cfaSignInFacebook, FacebookSignInResult, cfaSignInApple, cfaSignInPhoneOnCodeSent, cfaSignInPhoneOnCodeReceived, mapUserToUserInfo} from 'capacitor-firebase-auth';
import {cfaSignInTwitter, TwitterSignInResult} from 'capacitor-firebase-auth/alternative';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  authState = new BehaviorSubject({state:false, user:{}});

  constructor(
    private router: Router,
    private platform: Platform,
    public toastController: ToastController
  ) { 

    this.platform.ready().then(async () => {
      firebase.auth().onAuthStateChanged(async (user) => {
          if (user) {
            this.authState.next({state:true, user:user});
          } else {
            this.authState.next({state:false, user:{}});
          }
      });
    })
    
  
  }

  signUpWithEmailAndPassword(email: string, password: string) {
    return firebase.auth().createUserWithEmailAndPassword(email, password)
  }

  signInWithEmailAndPassword(email: string, password: string) {
    return firebase.auth().signInWithEmailAndPassword(email, password)
  }

  sendEmailVerification() {
    return firebase.auth().currentUser.sendEmailVerification() 
  }

  sendPasswordResetEmail(email:string) {
    return firebase.auth().sendPasswordResetEmail(email)
  } 


  isAuthenticated() {
    return this.authState.value
  }

}
