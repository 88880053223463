// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import firebase from "firebase/app";
import initializeApp from "firebase/app";
import "@firebase/auth";

const 
firebaseConfig = {
  apiKey: "AIzaSyCJV9IT95bT4HkV9hS6FI9EWrTWu4o0Sl4",
  authDomain: "glisspass.firebaseapp.com",
  databaseURL: "https://glisspass-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "glisspass",
  storageBucket: "glisspass.appspot.com",
  messagingSenderId: "1077471750223",
  appId: "1:1077471750223:web:f26be7daead4e88bfd223a",
  measurementId: "G-D5H19MGKZE"
};

try {
  firebase.initializeApp(firebaseConfig);
} catch (e) {}
export default firebase;

export const environment = {
  production: false,
  VTSConfig: {
    appName: "GLISSPASS",
  },
  onesignal: {
    appId: 'd9f05b93-0803-4c15-bb8c-d8118f724edb',
    googleProjectNumber: '729647101174',
    restKey: 'MGU4MmExZmItZDcxNS00ZjM2LTk1ZGUtYTJjOTg1YmNjZjk3'
  },
  mapsKey: "AIzaSyD9psS7_yzG1_0A5-SroxmejxivqPOC2Y4",
    //Country Phone Auth
    CountryJson: [
      { name: 'France', dial_code: '+33', code: 'FR' },
      { name: 'Afghanistan', dial_code: '+93', code: 'AF' },
      { name: 'Albania', dial_code: '+35', code: 'AL' },
      { name: 'Algeria', dial_code: '+213', code: 'DZ' },
      { name: 'AmericanSamoa', dial_code: '+1 684', code: 'AS' },
      { name: 'Andorra', dial_code: '+376', code: 'AD' },
      { name: 'Angola', dial_code: '+244', code: 'AO' },
      { name: 'Anguilla', dial_code: '+1 264', code: 'AI' },
      { name: 'Antigua and Barbuda', dial_code: '+1268', code: 'AG' },
      { name: 'Argentina', dial_code: '+54', code: 'AR' },
      { name: 'Armenia', dial_code: '+374', code: 'AM' },
      { name: 'Aruba', dial_code: '+297', code: 'AW' },
      ]
  
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
