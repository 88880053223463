import { Component, NgZone } from '@angular/core';
import { Network } from '@capacitor/network';
import { Toast } from '@capacitor/toast';
import { Platform } from '@ionic/angular';
import { Storage } from '@capacitor/storage';
import { PushNotifications } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { AuthenticationService } from './services/authentication.service';
import { Device } from '@capacitor/device';
import { FirestoreService } from './services/firestore.service';
import { PrivacyScreen } from '@capacitor-community/privacy-screen';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { PlatformService } from '../app/services/platform.service'
import { Stripe } from '@capacitor-community/stripe';
import { SplashScreen } from '@capacitor/splash-screen';
import { AndroidFullScreen } from '@awesome-cordova-plugins/android-full-screen/ngx';
import { Keyboard } from '@capacitor/keyboard';
import { LocalNotifications } from '@capacitor/local-notifications';
import { Router } from '@angular/router';
import { ConfigService } from './services/config.service';
import {
  FirebaseDynamicLinks,
  LinkConfig,
} from '@pantrist/capacitor-firebase-dynamic-links';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  AppConfiguration:any
  isMaintenance:boolean = false
  dark = false;
  geolocalisation = false;
  assistant = false
  fullScreen = true
  sync = false
  doubleAuthSMS:boolean = false
  quickLoginSMS:boolean = false
  enablePASS:boolean = false
  permStatus:any
  isConnected:boolean
  user:any
  appVersion:any = {version:'web', build:'web'}
  isNetwork:boolean = true
  loadingCtrl:any

  appPagesClient = [
    {
      title: 'Mes activités',
      url: '/tabs/tab1',
      icon: 'qr-code'
    },

  ];

  constructor(
    private platform: Platform,
    public authenticationService: AuthenticationService,
    public firestore: FirestoreService,
    public platformService: PlatformService,
    private AndroidFullScreen: AndroidFullScreen,
    private router: Router,
    private AppConfig: ConfigService,
    private zone: NgZone,

  ) {
    Stripe.initialize({
      publishableKey: 'pk_live_51Hcw4pJB6BXIe806G4QiNkB4z61jiptWA5wJIsMsa9SkmW8JafDJd310nIYFDYos5TJJmeOlJaML2apzNnuj7ok6008ksajIVw',
    });
    this.platform.ready().then(async () => {
      this.initializeApp()
     });

  }

   async initializeApp() {

      FirebaseDynamicLinks.addListener('deepLinkOpen', (data) => {
        console.log(data);
        const url = new URL(data.url)
        const redirectionLink = url.pathname.split('page.link').pop();
        this.router.navigate([redirectionLink])
    });


    //Get Current User Theme Preference
    const prefersColor = window.matchMedia('(prefers-color-scheme: dark)');

    //Get Storage Theme
    const { value } = await Storage.get({ key: 'theme' });
    if(value == "dark") {
      this.dark = true
      document.body.classList.add('dark-theme')
    }
    if(value == "light") {
      this.dark = false
      document.body.classList.remove('dark-theme')
    }
    if(value !== "light" && value !== "dark") {
    this.dark = prefersColor.matches;
    }
    await this.updateDarkMode();

    //Listen change/toggle theme (in Parameter Section)
    prefersColor.addEventListener(
      'change',
      mediaQuery => {
        this.dark = mediaQuery.matches;
        this.updateDarkMode();
      }
      );

      // Hide the splash (you should do this on app launch)
      await SplashScreen.hide();

      //Get App Info
      if(this.platformService.isNative()) {
        this.appVersion = await App.getInfo()
        //console.log('APP Version',  this.appVersion)
      }

      //Get Network Status
      const status = await Network.getStatus();
      if(!status.connected) {
        this.isNetwork = false
        Toast.show({
          text: 'Pas de connexion internet',
          duration: 'long'
        });
      } else {
        this.isNetwork = true
      }
      //Listen change network status
      Network.addListener('networkStatusChange', status => {
        if(!status.connected) {
          this.isNetwork = false
          Toast.show({
            text: 'Pas de connexion internet',
            duration: 'long'
          });
        } else {
          this.isNetwork = true
        }
      });


      //get Storage Vocal Assistant
      const assistant = await Storage.get({ key: 'assistant' });
      if(assistant !== null && assistant.value === 'active') {
        this.assistant = true
      }

      //get Storage FullScreen
      const fullScreen = await Storage.get({ key: 'fullScreen' });
      if(fullScreen !== null && fullScreen.value === 'active') {
        this.fullScreen = true
        if(this.platformService.isAndroid()) {
          // Hide system UI and keep it hidden (Android 4.4+ only)
          this.AndroidFullScreen.immersiveMode();
        }
      }

      //get Storage Vocal Assistant
      const geolocalisation = await Storage.get({ key: 'geolocalisation' });
      if(geolocalisation !== null && geolocalisation.value === 'true') {
        this.geolocalisation = true
      }

      //get Storage Sync Parameter
      const Sync = await Storage.get({ key: 'sync' });
      if(Sync.value === 'active') {
        this.sync = true
      }

       //get enablePASS State
       const enablePASS = await Storage.get({ key: 'enablePASS' });
       if(enablePASS.value === 'active') {
         this.enablePASS = true
       } else {
        this.enablePASS = false
       }


       //get Storage quickLoginSMS
       const quickLoginSMS = await Storage.get({ key: 'quickLoginSMS' });
       if(quickLoginSMS !== null && quickLoginSMS.value === 'active') {
         this.quickLoginSMS = true
       }

        //get Storage quickLoginSMS
        const doubleAuthSMS = await Storage.get({ key: 'doubleAuthSMS' });
        if(doubleAuthSMS !== null && doubleAuthSMS.value === 'active') {
          this.doubleAuthSMS = true
        }

    //Check if user is connected for display Menu
    this.authenticationService.authState.subscribe(async (state) => {
      if (state.state) {
        this.user = state['user']
        this.isConnected = true
        await this.delay(1000)

        //Check Notification Push
        const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');
        // User device is native
        if (isPushNotificationsAvailable) {
            this.initPushNotifications();
        } else {
          //User device is on web
          const result = await Notification.requestPermission();
          if (result === 'granted') {
            /*const noti = new Notification('Hello!', {
              body: 'It’s me.',
            });*/
          } else {
            }
        } //

      } else {
        this.isConnected = false
      }
    });

  }

  async initPushNotifications() {
    const registrationNotification = await PushNotifications.addListener('registration', token => {
      console.log('Registration token: ', token.value);
      this.saveTokenPushNotification(token)
    });

    const registrationNotificationError = await PushNotifications.addListener('registrationError', err => {
      console.error('Registration error: ', err.error);
    });

    const pushNotificationReceived = await PushNotifications.addListener('pushNotificationReceived', notification => {
      console.log('Push notification received: ', notification);
      this.localNotification(notification)
    });

   const pushNotificationActionPerformed = await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
      console.log('Push notification action performed', notification.actionId, notification.notification.id);
      console.log('action push', notification)
      this.router.navigateByUrl(notification.notification.data.redirection)
    });

    const localNotificationActionPerformed = await LocalNotifications.addListener('localNotificationActionPerformed', notification => {
      console.log('Push notification action performed', notification.actionId, notification.notification.id);
      console.log('action push', notification)
      const extra = notification.notification['extra']
      console.log('EXTRA PUSH', extra)
      extra.forEach(element => {
        if(element == "redirection") {
          this.router.navigateByUrl(notification.notification['extra'].redirection)
        }
      });

    });

    this.permStatus = await PushNotifications.checkPermissions();

    if (this.permStatus.receive === 'prompt' || this.permStatus.receive === 'denied') {
      this.permStatus = await PushNotifications.requestPermissions();
    }

    if (this.permStatus.receive !== 'granted') {
    } else { /*console.log('User have permissions!');*/ }

    const pushNotificationRegister = await PushNotifications.register();

    const notificationList = await PushNotifications.getDeliveredNotifications();
    //console.log('delivered notifications', notificationList);

  }


  saveTokenPushNotification(token) {
    this.firestore.saveTokenPushNotification(token).then(async () => {

    })
  }



  async localNotification(notification) {
    const notifs = await LocalNotifications.schedule({
      notifications: [
        {
          title: notification.title,
          body: notification.body,
          largeBody: notification.body,
          id: Math.floor(Math.random() * 500),
          schedule: { at: new Date(Date.now() + 1000 * 1) },
          sound: null,
          attachments: null,
          actionTypeId: '',
          extra: {redirection:notification.redirection},
        },
      ],
    });
    console.log('scheduled notifications', notifs);
  }


 async updateDarkMode() {
  await Storage.remove({ key: 'theme' });

    if(this.dark) {
      document.body.classList.add('dark-theme');
      const setdark = await Storage.set({
        key: 'theme',
        value: 'dark'
      });

    } else {
      document.body.classList.remove('dark-theme');
      const setdark = await Storage.set({
        key: 'theme',
        value: 'light'
      });

    }
  }

  async updateGeolocalisation(state) {
    await Storage.remove({ key: 'geolocalisation' });

      if(state) {
        this.geolocalisation = true
        const setGeolocalisation = await Storage.set({
          key: 'geolocalisation',
          value: 'true'
        });

      } else {
        this.geolocalisation = false
        const setGeolocalisation = await Storage.set({
          key: 'geolocalisation',
          value: 'false'
        });

      }
    }


 async updateAssistantVocal(event) {
  await Storage.remove({ key: 'assistant' });
    if(event.detail.checked) {
      this.assistant = true
      const setassistant = await Storage.set({
        key: 'assistant',
        value: 'active'
      });

    } else {
      this.assistant = false
      const setassistant = await Storage.set({
        key: 'assistant',
        value: 'desactive'
      });

    }
  }

  async updateFullScreen(event) {
    await Storage.remove({ key: 'fullScreen' });
      if(event.detail.checked) {
        this.enableFUllScreen()
        this.fullScreen = true
        const setfullScreen = await Storage.set({
          key: 'fullScreen',
          value: 'active'
        });

      } else {
        this.disableFUllScreen()
        this.fullScreen = false
        const setfullScreen = await Storage.set({
          key: 'fullScreen',
          value: 'desactive'
        });

      }
    }

  disableFUllScreen() {
    // Show system UI
    this.AndroidFullScreen.showSystemUI();
  }

  enableFUllScreen() {
    // Show system UI
    this.AndroidFullScreen.immersiveMode();
  }

  async updatequickLoginSMS(event) {
    await Storage.remove({ key: 'quickLoginSMS' });
      if(event.detail.checked) {
        this.quickLoginSMS = true
        const setquickLoginSMS = await Storage.set({
          key: 'quickLoginSMS',
          value: 'active'
        });

      } else {
        this.quickLoginSMS = false
        const setquickLoginSMS = await Storage.set({
          key: 'quickLoginSMS',
          value: 'desactive'
        });

      }
    }


  async updatedoubleAuthSMS(event) {
    await Storage.remove({ key: 'doubleAuthSMS' });
      if(event.detail.checked) {
        this.doubleAuthSMS = true
        const setdoubleAuthSMS = await Storage.set({
          key: 'doubleAuthSMS',
          value: 'active'
        });

      } else {
        this.doubleAuthSMS = false
        const setdoubleAuthSMS = await Storage.set({
          key: 'doubleAuthSMS',
          value: 'desactive'
        });

      }
    }






  async firstSaveSync(user) {
    await Storage.remove({ key: 'saveSync' });
      const { value } = await Storage.get({ key: 'saveSync' });
      if(value == null) {
        const SyncParameter = {
          sync: this.sync,
          darkTheme: this.dark,
          assistant: this.assistant,
          fullScreen: this.fullScreen,
          appVersion: this.appVersion,
          quickLoginSMS: this.quickLoginSMS,
          doubleAuthSMS: this.doubleAuthSMS,
          enablePASS: false,
          geolocalisation: false
        }
      this.firestore.saveSyncParameters(user.uid, SyncParameter).then(async () => {
        //console.log('save Sync Parameter', SyncParameter)
        const userStorage = await Storage.set({
          key: 'saveSync',
          value: JSON.stringify({
            SyncParameter
          })
        });
      }).catch((error) => {
        //console.log('save Device Parameter',error)
      })
      }
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
}


authStateChanged(state:boolean, data:any) {
  if (state) {
    //console.log('LE USER', state)
    this.user = data
    this.isConnected = true
    //console.log('youhooo user is connected')
  } else {
    this.isConnected = false
    //console.log('oups user is not connected')
  }
}


changeName(user:any) {
  this.user = user
}



}
